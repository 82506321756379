import React from 'react'
import './css/Footer.scss'

function Footer() {
  return (
    <footer>
      <span>Footer Content</span>
    </footer>
  )
}

export default Footer